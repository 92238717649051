import {get} from "lodash";
import { ApiDataAdapter } from '@internal-libraries/kheops-ui-lib/src/services/RestClient/Interfaces/ApiDataAdapter'
import { ChainingCatalogDto } from '../types/ChainingCatalogDto'
import {ChainingCareCatalogApiAdapter} from './ChainingCareCatalogApiAdapter'
import { ChainingCareCatalog } from '../classes/ChainingCareCatalog';
import ChainingCatalog from '../classes/ChainingCatalog';
import { Undefinable } from '@internal-libraries/kheops-ui-lib/dist/types/common/types/Helpers';
import { ChainingDrugCatalogApiAdapter } from "./ChainingDrugCatalogApiAdapter";
import {ChainingDrugCatalog} from "../classes/ChainingDrugCatalog";
import {ChainingCareCatalogDto} from "../types/ChainingCareCatalogDto";
import { ChainingDrugCatalogDto } from "../types/ChainingDrugCatalogDto";
import ChainingOperation from "@module/chaining/vue/Components/ChainingOperation/ChainingOperation";
import { ChainingOperationCatalogApiAdapter } from "./ChainingOperationCatalogApiAdapter";
import {ChainingOperationCatalogDto} from "@module/chaining/vue/types/ChainingOperationCatalogDto";
import { ChainingOperationCatalog } from "../classes/ChainingOperationCatalog";


export class ChainingCatalogApiAdapter implements ApiDataAdapter {
    createClass(apiData: ChainingCatalogDto): ChainingCatalog {
        let objectClass: string = get(apiData, 'object_class')
        let chainingCatalogObject: Undefinable<ChainingCareCatalog | ChainingDrugCatalog | ChainingOperationCatalog>
        const refObject = get(apiData, 'ref_object')
        if (refObject) {
            switch (objectClass) {
                case 'CElementPrescription':
                    chainingCatalogObject = new ChainingCareCatalogApiAdapter().createClass(
                        refObject as ChainingCareCatalogDto
                    )
                    break
                case 'CCompendiumProduct':
                    chainingCatalogObject = new ChainingDrugCatalogApiAdapter().createClass(
                        refObject as ChainingDrugCatalogDto
                    )
                    break
                case 'CProtocole':
                    chainingCatalogObject = new ChainingOperationCatalogApiAdapter().createClass(
                        refObject as ChainingOperationCatalogDto
                    )
                    break
            }
        }

        return new ChainingCatalog(
            get(apiData, 'id'),
            get(apiData, 'object_class'),
            get(apiData, 'object_id'),
            get(apiData, 'chaining_id'),
            get(apiData, 'type_id'),
            get(apiData, 'is_active'),
            get(apiData, 'creation_date'),
            chainingCatalogObject || undefined
        )
    }
}
