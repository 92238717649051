var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "chaining_management_context" },
    [
      _c("kh-form", {
        attrs: {
          name: _vm.formName,
          schema: _vm.schemaFormChainingContext,
          form: _vm.form,
        },
        on: {
          "update:form": function ($event) {
            _vm.form = $event
          },
          "validation-form": _vm.onValidation,
        },
        model: {
          value: _vm.formData,
          callback: function ($$v) {
            _vm.formData = $$v
          },
          expression: "formData",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }