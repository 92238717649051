import {ApiDataAdapter} from "@internal-libraries/kheops-ui-lib/src/services/RestClient/Interfaces/ApiDataAdapter"
import { ChainingCareCatalogDto } from '../types/ChainingCareCatalogDto'
import {get} from "lodash"
import {ChainingCareCatalog} from '../classes/ChainingCareCatalog'
import { CategoryPrescriptionAdapter } from './CategoryPrescriptionAdapter'

export class ChainingCareCatalogApiAdapter implements ApiDataAdapter {
    createClass(apiData: ChainingCareCatalogDto): ChainingCareCatalog {
        let categoryPrescription
        if (apiData.ref_category) {
            categoryPrescription = new CategoryPrescriptionAdapter().createClass(
                apiData.ref_category
            )
        }

        return new ChainingCareCatalog(
            get(apiData, 'id'),
            get(apiData, 'libelle'),
            get(apiData, 'description'),
            get(apiData, 'category_prescription_id'),
            categoryPrescription
        )
    }
}
