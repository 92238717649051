import Vue from 'vue'
import _ from 'lodash'
import { format } from 'date-fns'
import {ChainingCatalogContextEnum} from '../types/ChainingCatalogContext'
import { CHAINING_EMPTY_SPECIALTY_ID } from '../types/ChainingDefault'
import {ChainingPermissions} from '../types/ChainingPermissions'

export default class ChainingHelper {
    public static compareTwoStrings(nameA: string, nameB: string): number {
        return _.camelCase(nameA).localeCompare(_.camelCase(nameB))
    }

    public static readonly DEFAULT_COLOR_CARD: string = '#e5e9ef'
    public static readonly MAX_SERVICE_NUMBER: number = 999

    public static formattedTodayDate(): string {
        return format(new Date(), 'yyyy-MM-dd')
    }

    public static formatDate(dateToFormat: string): string {
        return format(new Date(dateToFormat), 'dd-MM-yyyy')
    }

    public static formatHour(dateToFormat: string): string {
        return format(new Date(dateToFormat), 'HH:mm')
    }

    public static formatDateToDateString(dateToFormat: Date): string {
        return format(dateToFormat, 'dd-MM-yyyy')
    }

    public static formatDateToHourString(dateToFormat: Date): string {
        return format(dateToFormat, 'HH:mm')
    }

    public static getContextIconByType(type: ChainingCatalogContextEnum): string {
        switch(type) {
            case ChainingCatalogContextEnum.CONTEXT_DRUG:
                return 'mdi-pill'
            case ChainingCatalogContextEnum.CONTEXT_CARE:
                return 'mdi-medical-bag'
            case ChainingCatalogContextEnum.CONTEXT_OPERATION:
                return 'mdi-face-mask-outline'
            default:
                console.error('[getContextIconByType] - No icon found for type ', type)
                return ''
        }
    }

    public static isSpecialtyEqualToAllSpecialty(specialtyId: number): boolean {
        return specialtyId === CHAINING_EMPTY_SPECIALTY_ID
    }

    public static setFullChainingPermissions(): void {
        for(const chainingPermission in ChainingPermissions) {
            Vue.prototype.$permissionsService.set(ChainingPermissions[chainingPermission], true)
        }
    }
}
