import {ChainingCatalogContext} from '../types/ChainingCatalogContext/ChainingCatalogContext'
import {Nullable} from "@internal-libraries/kheops-ui-lib/dist/types/common/types";

export default class ChainingType {
    constructor(
        public id: number,
        public name: string,
        public entityType: string,
        public isActive: boolean,
    ) {
    }

    public static getContextFromId(id: number, types: ChainingCatalogContext[]): Nullable<ChainingCatalogContext> {
        return types.find(type => {
            return type.id === id
        }) || null
    }
}
