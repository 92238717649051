import {KhComponent, Snack, SnackColor, SnackDelay} from '@internal-libraries/kheops-ui-lib'
import {Nullable, OneOrMore} from '@internal-libraries/kheops-ui-lib/dist/types/common/types'
import {Inject, Component} from 'vue-property-decorator'
import _ from 'lodash'

import ChainingService from '../classes/ChainingService'
import Chaining from '../classes/Chaining'
import AdministratedProduct from '../classes/AdministratedProduct'
import ChainingHelper from '../classes/ChainingHelper'
import ChainingProvider from '../classes/ChainingProvider'
import ChainingCategory from '../classes/ChainingCategory'

import {ChainingKhList, ChainingPanelList, ChainingPanels} from '../types/ChainingKhList'
import {ChainingFilter} from '../types/ChainingFilter'
import {ServiceKhList} from '../types/ServiceKhList'
import {ChainingCatalogContext, ChainingCatalogContextEnum} from '../types/ChainingCatalogContext'
import {ChainingStoreDefaultChainingLoad} from '../types/ChainingStoreDefaultChainingLoad'
import {ChainingPatientServiceStatus} from '../types/ChainingPatientService/ChainingPatientServiceStatus'
import * as ChainingDefaultConstants from '../types/ChainingDefault/ChainingDefaultConstants'
import {ChainingStoreAddAssociatedPatientService, ChainingStoreAddPatientService} from '../store/types/'
import {ChainingPatientServiceCreateDto, ChainingPatientServiceUpdateDto} from '../types/ChainingPatientService'
import {
    ChainingAssociatedPatientServiceCreateDto,
    ChainingAssociatedPatientServiceService,
    ChainingAssociatedPatientServiceUpdateDto
} from '../types/ChainingAssociatedPatientService'
import { ChainingSpecialty } from '../types/ChainingSpecialty'
import {ChainingUserPreferenceCreateDto} from '../types/ChainingUserPreference/ChainingUserPreferenceCreateDto'
import {ChainingUserPreferenceUpdateDto} from '../types/ChainingUserPreference/ChainingUserPreferenceUpdateDto'
import {ChainingStoreAdministratedProductLoad} from '../store/types/ChainingStoreAdministratedProduct/ChainingStoreAdministratedProductLoad'
import { ChainingSpecialtyDto } from '../types/ChainingSpecialtyDto'
import { ChainingCategoryDto } from '../types/ChainingCategoryDto'
import ClvrComponent from "@api/ClvrComponent";

/**
 * Abstract class defining all store and helping functions
 */
// @ts-ignore
@Component
export default abstract class ChainingComponentStore extends ClvrComponent {
    @Inject({ default: 'defaultKey' })
    protected storeKey!: string

    public readonly ASK_CLEVEHR_UPDATE_EVENT = 'askClevehrUpdate'
    public readonly STORE_INIT_FROM_CATALOG = 'initFromCatalog'
    public readonly STORE_INIT_FROM_OBJECT = 'initFromObject'

    protected maxChainingServiceNumber = ChainingHelper.MAX_SERVICE_NUMBER
    protected chainingList: ChainingKhList = new ChainingKhList([])
    protected serviceList: ServiceKhList = new ServiceKhList([])
    protected selectedServices: ChainingService[] = []
    protected internalChainings: Chaining[] = []

    protected filters?: ChainingFilter


    /** GETTERS */
    protected get allSpecialtyId(): number {
        return ChainingDefaultConstants.CHAINING_EMPTY_SPECIALTY_ID
    }

    protected get emptyChaining(): Chaining {
        let returnedChaining = new Chaining(
            ChainingDefaultConstants.CHAINING_VIRTUAL_ID,
            this.$t('chaining:chaining-additional-services'),
            this.specialtyId
        )
        returnedChaining.specialtyName = this.retrieveChainingSpecialtyName(this.specialtyId)
        return returnedChaining
    }

    protected get chainingServices(): ChainingService[] {
        return this.$store.getters.getChainingServices(this.storeKey)
    }

    protected get chainingProviderId(): number {
        return this.$store.getters.getChainingProviderId(this.storeKey)
    }

    protected get chainingDefaultProviderPreferenceId(): number {
        return this.$store.getters.getChainingDefaultProviderPreferenceId(this.storeKey)
    }

    protected get chainingProviders(): ChainingProvider[] {
        return this.$store.getters.getChainingProviders(this.storeKey)
    }

    protected get allChainingList(): Chaining[] {
        return this.$store.getters.getAllChainingList(this.storeKey)
    }
    protected get allChainingWithCatalogs(): Chaining[]{
        return this.$store.getters.getAllChainingWithCatalogs(this.storeKey)
    }

    protected get chainingDefaultList(): Chaining[] {
        return this.$store.getters.getChainingDefault(this.storeKey)
    }

    protected get groupId(): number {
        return this.$store.getters.getChainingGroupId(this.storeKey)
    }

    protected get chainingDefaultSpecialtyPreferenceId(): number {
        return this.$store.getters.getChainingDefaultSpecialtyPreferenceId(this.storeKey)
    }

    protected get chainingUserId(): number {
        return this.$store.getters.getChainingUserId(this.storeKey)
    }

    protected get specialtyId(): number {
        return this.$store.getters.getChainingSpecialtyId(this.storeKey) || ChainingDefaultConstants.CHAINING_EMPTY_SPECIALTY_ID
    }

    protected get contexts(): ChainingCatalogContext[] {
        return this.$store.getters.getChainingTypes.map(chainingType => {
            return {
                id: chainingType.id,
                name: chainingType.name,
                code: chainingType.entityType,
            }
        })
    }

    protected setSpecialtyId(id: number): void {
        this.$store.dispatch('setChainingSpecialtyId', {
            storeKey: this.storeKey,
            id: id
        })
    }

    protected setDefaultSpecialtyId(id: number): void {
        this.$store.dispatch('setChainingDefaultSpecialtyId', {
            storeKey: this.storeKey,
            id: id
        })
        let idToSet: string | number = id === ChainingDefaultConstants.CHAINING_EMPTY_SPECIALTY_ID ? '' : id
        if (this.chainingDefaultSpecialtyPreferenceId) {
            this.$store.dispatch('updateUserDefaultPreference', {
                pref_id: this.chainingDefaultSpecialtyPreferenceId,
                value: idToSet,
            } as ChainingUserPreferenceUpdateDto)
                .then(() => {
                    this.snackbarManager.addSnack(new Snack(
                        this.$t('chaining:chaining-specialty_updated'),
                    ))
                })
                .catch( () => {
                    this.snackbarManager.addSnack(new Snack(
                        this.$t('clevehr:common-error_occured'),
                        SnackColor.error
                    ))
                })
        } else {
            this.$store.dispatch('createUserDefaultPreference', {
                user_id: this.chainingUserId,
                key: 'default_specialty',
                value: idToSet,
                restricted: 0,
                storeKey: this.storeKey
            } as ChainingUserPreferenceCreateDto)
                .then((insertedId: number) => {
                    this.snackbarManager.addSnack(new Snack(
                        this.$t('chaining:chaining-specialty_updated'),
                    ))
                })
                .catch( (e) => {
                    console.error(e)

                    this.snackbarManager.addSnack(new Snack(
                        this.$t('clevehr:common-error_occured'),
                        SnackColor.error
                    ))
                })
        }
    }

    protected get defaultSpecialtyId(): number {
        return this.$store.getters.getChainingDefaultSpecialtyId(this.storeKey) || ChainingDefaultConstants.CHAINING_EMPTY_SPECIALTY_ID
    }

    protected get drugBase(): string {
        return this.$store.getters.getChainingDrugBase(this.storeKey)
    }

    protected get drugBaseLanguage(): string {
        return this.$store.getters.getChainingDrugBaseLanguage(this.storeKey)
    }
    
    protected get objectId(): number {
        return this.$store.getters.getChainingObjectId(this.storeKey)
    }

    protected get objectClass(): string {
        return this.$store.getters.getChainingObjectClass(this.storeKey)
    }

    protected get allSpecialty(): ChainingSpecialty {
        return {
            id: ChainingDefaultConstants.CHAINING_EMPTY_SPECIALTY_ID,
            name: this.$t('chaining:chaining-all-specialty')
        }
    }

    protected get specialties(): ChainingSpecialty[] {
        return [this.allSpecialty].concat(this.$store.getters.getChainingSpecialties.concat(this.allSpecialty).sort((a,b) => ChainingHelper.compareTwoStrings(a.name,b.name)))
    }

    protected get careCatalogs() {
        return this.$store.getters.getChainingCareCatalogs(this.storeKey)
    }

    protected get categories(): ChainingCategory[] {
        return this.$store.getters.getChainingCategories(this.storeKey)
    }

    protected get categoriesBySpecialtyId(): ChainingCategory[] {
        return  this.categories.filter(category => {
            return this.specialtyId === ChainingDefaultConstants.CHAINING_EMPTY_SPECIALTY_ID
                || category.specialty_id == this.specialtyId
        })
    }

    protected get chainingWithServices(): Chaining {
        return this.$store.getters.getChainingWithServices(this.storeKey)
    }

    protected get chainingsFromService(): Chaining[] {
        return this.$store.getters.getChainingsFromService(this.storeKey)
    }

    protected get internalAndVirtualChainings(): Chaining[] {
        return this.internalChainings
    }

    protected get chainingLastAssociatedPatientServiceId(): number {
        return this.$store.getters.getChainingLastAssociatedPatientServiceId(this.storeKey)
    }

    protected get chainingLastPatientServiceId(): number {
        return this.$store.getters.getChainingLastPatientServiceId(this.storeKey)
    }

    protected get chainingAdministratedProducts(): AdministratedProduct[] {
        return this.$store.getters.getChainingAdministratedProducts(this.storeKey)
    }

    /** METHODS */
    protected sortChainingServicesByName(chainings: OneOrMore<Chaining>): void {
        if (Array.isArray(chainings)) {
            chainings.forEach(chaining => {
                this.sortChainingOrServicesByName(chaining.services)
            })
        } else {
            this.sortChainingOrServicesByName(chainings.services)
        }
    }

    protected sortChainingOrServicesByName(chainingsOrServices: ChainingService[] | Chaining[]): void {
        chainingsOrServices.sort((a,b) => ChainingHelper.compareTwoStrings(a.name,b.name))
    }

    protected setSpecialtyAndCategoryNames(chainings: Chaining[]): void {
        chainings.forEach( chaining => {
            chaining.specialtyName = this.retrieveChainingSpecialtyName(chaining.specialtyId)
            chaining.categoryName = this.retrieveChainingCategoryName(chaining.categoryId)
        })
    }

    protected retrieveChainingCategoryName(categoryId?: number): string {
        if (!categoryId) return ''
        const result = this.categories.filter((category) => category.id == categoryId)
        if (!result?.length) return ''
        return result[0].name
    }

    protected retrieveChainingSpecialtyName(specialtyId?: number): string {
        if (!specialtyId || specialtyId === ChainingDefaultConstants.CHAINING_EMPTY_SPECIALTY_ID) return this.$t('chaining:chaining-all-specialty')
        const result = this.specialties.filter((specialty) => specialty.id == specialtyId)
        if (!result?.length) return ''

        return result[0].name
    }

    protected loadDefaultChainings(apiRoad: string, specialtyId: number, showAllSpecialty = false, objectClass: string = this.objectClass, objectId: number = this.objectId, storeKey: string = this.storeKey): Nullable<Promise<Chaining[]>> {
        if (objectClass !== '' &&
            objectId !== ChainingDefaultConstants.CHAINING_NO_INSERTED_ID) {
            return this.$store
                .dispatch('loadDefaultChainings', {
                    apiRoad: apiRoad,
                    objectClass: objectClass,
                    objectId: objectId,
                    storeKey: storeKey,
                    specialtyId: specialtyId === ChainingDefaultConstants.CHAINING_EMPTY_SPECIALTY_ID ? null : specialtyId,
                    showAllSpecialty: showAllSpecialty
                } as ChainingStoreDefaultChainingLoad)
        } else {
            return null
        }
    }

    protected loadCareFollowUpAdministratedProducts(sejourId: number, storeKey: string = this.storeKey, dateMin: string = ChainingHelper.formattedTodayDate(), dateMax: string = ChainingHelper.formattedTodayDate()): Promise<AdministratedProduct[]> {
        return this.$store.dispatch('loadCareFollowUpAdministratedProducts', {
            dateMin: dateMin,
            dateMax: dateMax,
            sejourId: sejourId,
            storeKey: storeKey
        } as ChainingStoreAdministratedProductLoad)
    }

    protected emptyInternalChainings(): void {
        this.internalChainings = []
    }

    protected emptyChainingServices(virtualChaining: Chaining): void {
        virtualChaining.services = []
    }

    protected addInternalChaining(chaining: Chaining): void {
        chaining.services?.sort((a, b) => ChainingHelper.compareTwoStrings(a.name, b.name))
        this.internalChainings.push(chaining)
    }

    protected addSpecialty(specialty: Partial<ChainingSpecialtyDto>):  Promise<ChainingSpecialty> {
        return this.$store.dispatch('addSpecialty', specialty)
    }

    protected addCategory(category: Partial<ChainingCategoryDto>):  Promise<ChainingCategory> {
        return this.$store.dispatch('addCategory', {
            category,
            storeKey: this.storeKey
        })
    }

    protected deleteSpecialty(specialtyId: number):  Promise<ChainingSpecialty> {
        return this.$store.dispatch('deleteSpecialty', specialtyId)
    }

    protected deleteCategory(categoryId: number):  Promise<ChainingCategory> {
        return this.$store.dispatch('deleteCategory', {
            categoryId,
            storeKey: this.storeKey
        })
    }

    protected retrieveChainingByService(service: ChainingService, editionMode: boolean = false): Chaining | null {
        // editionMode every chaining is stored in DB so associatedPatientServiceId exist
        if (editionMode) {
            const tmpList: Chaining[] = this.internalChainings.filter((chaining: Chaining) => chaining.associatedPatientServiceId == service.associatedPatientServiceId)
            if (tmpList.length) {
                return tmpList[0]
            }
            return null
        } else if (service.chainingId === ChainingDefaultConstants.CHAINING_VIRTUAL_ID) {
            const tmpList: Chaining[] = this.internalChainings.filter((chaining: Chaining) => chaining.specialtyId == service.specialtyId)
            if (tmpList.length) {
                return tmpList[0]
            }
            return null
        } else {
            const tmpList: Chaining[] = this.internalChainings.filter((chaining: Chaining) => chaining.id == service.chainingId)
            if (tmpList.length) {
                return tmpList[0]
            }
            return null
        }
    }


    protected findServiceByPrestationId(serviceList: ChainingService[], prestationId: number): Nullable<ChainingService> {
        const matchingServices = serviceList.filter(service => service.prestationCaisseId == prestationId)
        if (matchingServices.length === 1) {
            return matchingServices[0]
        }
        return null
    }

    /**
     * Add services to a chaining
     * returns all added services
     * @param chaining
     * @param services
     * @return  [ChainingService[] - services added, ChainingService[] - service quantity update]
     * @protected
     */
    protected addServiceToChaining(chaining: Chaining, services: ChainingService[]): [ChainingService[], ChainingService[]] {
        let clonedServices: ChainingService[] = _.cloneDeep(services)

        if (chaining.services.length) {
            // we update service quantity of service that are not validated from in chaining service list
            const notValidatedServicePrestationIds: number[] =
                chaining.services
                .filter(service => service.status === ChainingPatientServiceStatus.pending)
                .map( service => Number(service.prestationCaisseId))
            const serviceToUpdateQuantity = clonedServices.filter( service => notValidatedServicePrestationIds.indexOf(service.prestationCaisseId) !== -1)
            let serviceFound: Nullable<ChainingService> = null
            let serviceQuantityUpdatedList: ChainingService[] = []
            serviceToUpdateQuantity.forEach( service => {
                serviceFound = this.findServiceByPrestationId(chaining.services, service.prestationCaisseId)
                if (serviceFound) {
                    serviceQuantityUpdatedList.push(serviceFound)
                    serviceFound.quantity = Number(serviceFound.quantity)
                    serviceFound.quantity += service.quantity
                }
            })

            // we add services that are not present the chaining service list
            const servicePrestationIds: number[] = chaining.services.map( service => Number(service.prestationCaisseId))
            const serviceToAdd: ChainingService[] = clonedServices.filter( service => servicePrestationIds.indexOf(service.prestationCaisseId) === -1)
            chaining.services = chaining.services.concat(serviceToAdd).sort((a, b) => ChainingHelper.compareTwoStrings(a.name, b.name))

            if (clonedServices.length - (serviceToAdd.length + serviceToUpdateQuantity.length) > 0) {
                // at least one service will be added since it exists but is validated or billed
                // @TODO snackbar
                console.error('[addServiceToChaining] - At least one service already exist and is validated/billed, so no addition can be performed')
            }

            return [serviceToAdd, serviceQuantityUpdatedList]
        } else {
            chaining.services = clonedServices
            return [clonedServices, []]
        }
    }

    protected deleteServiceFromChaining(service: ChainingService, chaining: Chaining): void {
        const serviceIndex = chaining.services.indexOf(service)
        if (serviceIndex !== -1) {
            chaining.services.splice(serviceIndex, 1)
            const chainingSelectedServiceIndex = chaining.selectedServices.indexOf(serviceIndex)
            if (chainingSelectedServiceIndex !== -1) {
                chaining.selectedServices.splice(chainingSelectedServiceIndex, 1)
            }
            const selectedServiceIndex = this.selectedServices.indexOf(service)
            if (selectedServiceIndex !== -1) {
                this.selectedServices.splice(selectedServiceIndex, 1)
            }
        }
    }

    protected deleteInternalChainingFromIndex(index: number): void {
        this.internalChainings.splice(index, 1)
    }

    protected deleteInternalChainingServiceFromIndex(index: number, chainingIndex: number): void {
        this.internalChainings[chainingIndex].services.splice(index, 1)
    }

    protected deleteChaining(chaining: Chaining): Promise<boolean> {
        const index: number = this.internalAndVirtualChainings.indexOf(chaining)

        return this.$store.dispatch('deleteChaining', chaining.id)
            .then(() => {
                this.deleteInternalChainingFromIndex(index)
                return true
            })
            .catch((e) => {
                return false
            })
    }

    protected setChainingList(chainingList: Chaining[], openFirstPanel = false): void {
        this.chainingList.updateConfiguration(
            chainingList
                .slice()
                // always sort chainings by name and put those virtual at the end
                .sort((a, b) => {
                    if (a.id === ChainingDefaultConstants.CHAINING_VIRTUAL_ID) {
                        return 1
                    } else if (b.id === ChainingDefaultConstants.CHAINING_VIRTUAL_ID) {
                        return 0
                    } else {
                        return ChainingHelper.compareTwoStrings(a.name, b.name)
                    }
                }),
            this.filters,
            openFirstPanel
        )
    }


    protected setServiceList(serviceList: ChainingService[]): void {
        this.serviceList.updateConfiguration(serviceList.slice()
            .sort((a,b) =>
                ChainingHelper.compareTwoStrings(a.name, b.name)
        ))
    }

    /**
     * Method used to open a panel by an index
     * @param index
     * @protected
     */
    protected openPanelByIndex(index: number): void {
        if (!this.chainingList.isPanelOpened(index)) {
            this.chainingList.addOpenedPanels(index)
        }
    }

    protected get chainingListConfiguration(): ChainingPanels {
        return this.chainingList.getConfiguration()
    }

    protected get serviceListConfiguration(): ChainingPanelList {
        return this.serviceList.getConfiguration()
    }

    protected updateItemQuantity(quantity: number, item: ChainingService, updateClevehr: boolean = false): void {
        item.quantity = quantity
        if (updateClevehr) {
            this.askClevehrUpdate()
        }
    }

    protected retrieveProviderCodeById(providerId: number): string {
        return this.chainingProviders.filter(provider => provider.id == providerId)[0]?.code
            || this.$t('chaining:chaining-no-service-provider-code')
    }

    protected updateServiceProviderId(newProviderId: number, service: ChainingService, updateClevehr: boolean = false, editionMode: boolean = false): void {
        service.providerId = newProviderId
        if (updateClevehr) {
            this.askClevehrUpdate()
        }
        if (editionMode) {
            this.updateChainingPatientService(service)
        }
    }

    protected setProviderIdOnChainingServices(services: ChainingService[], providerId: number = this.chainingProviderId): void {
        services.forEach( service => service.providerId = providerId)
    }

    protected updateChainingProviderId(newProviderId: number): void {
        this.$store.dispatch('setChainingProviderId',  {
            id: newProviderId,
            storeKey: this.storeKey
        })
        if (this.chainingDefaultProviderPreferenceId) {
            this.$store.dispatch('updateUserDefaultPreference', {
                pref_id: this.chainingDefaultProviderPreferenceId,
                value: newProviderId,
            } as ChainingUserPreferenceUpdateDto)
                .then(() => {
                    this.snackbarManager.addSnack(new Snack(
                        this.$t('chaining:chaining-preference_provider_updated'),
                    ))
                })
                .catch( (e) => {
                    console.error(e)
                    this.snackbarManager.addSnack(new Snack(
                        this.$t('clevehr:common-error_occured'),
                        SnackColor.error,
                    ))
                })
        } else {
            this.$store.dispatch('createUserDefaultPreference', {
                user_id: this.chainingUserId,
                key: 'default_provider_code',
                value: newProviderId,
                restricted: 0,
                storeKey: this.storeKey
            } as ChainingUserPreferenceCreateDto)
                .then((insertedId: number) => {
                    this.snackbarManager.addSnack(new Snack(
                        this.$t('chaining:chaining-preference_provider_created'),
                        SnackColor.error
                    ))
                })
                .catch( (e) => {
                    console.error('error on load default chainings')
                    this.snackbarManager.addSnack(new Snack(
                        this.$t('clevehr:common-error_occured'),
                        SnackColor.error
                    ))
                })
        }
    }

    /**
     * Method used to retrieve a virtual chaining object by a specialty
     * @protected
     */
    protected findVirtualChainingBySpecialtyId(chainings: Chaining[] = this.internalChainings): Nullable<Chaining> {
        const virtualChainingFound = chainings.filter( chaining => chaining.id === ChainingDefaultConstants.CHAINING_VIRTUAL_ID && chaining.specialtyId === this.specialtyId)
        switch(virtualChainingFound.length) {
            case 0:
                return this.emptyChaining
            case 1:
                return virtualChainingFound[0]
            default:
                // Todo exception management should throw an error
                return null
        }
    }

    /** PATIENT SERVICE PART */

    /**
     *
     * @param serviceToAdd
     * @protected
     */
    protected addChainingPatientService(serviceToAdd: ChainingService): Promise<any> {
        return this.$store.dispatch('addChainingPatientService', {
            storeKey: this.storeKey,
            parameters: this.convertChainingToPatientServiceCreate(serviceToAdd)
        } as ChainingStoreAddPatientService).then( () => {
            if (this.chainingLastPatientServiceId !== ChainingDefaultConstants.CHAINING_NO_INSERTED_ID) {
                serviceToAdd.id = this.chainingLastPatientServiceId
            } else {
                // todo throw an error
                console.error('[addChainingPatientService] - no returned id provided')
            }
        })
    }

    /**
     * Method used to update a chaining within an api call
     * @param serviceToUpdate
     * @protected
     */
    protected updateChainingPatientService(serviceToUpdate: ChainingService): Promise<any> {
        return this.$store.dispatch('updateChainingPatientService', this.convertChainingToPatientServiceUpdate(serviceToUpdate))
    }

    /**
     * Method used to delete a chaining within an api call
     * @param serviceToDelete
     * @protected
     */
    protected deleteChainingPatientService(serviceToDelete: ChainingService): Promise<any> {
        return this.$store.dispatch('deleteChainingPatientService', this.convertChainingToPatientServiceUpdate(serviceToDelete))
    }

    /**
     * Private method used to format a Chaining type into a ChainingPatientServiceUpdateDto type
     * used to send data to clevehr
     * @param service
     * @private
     */
    private convertChainingToPatientServiceUpdate(service: ChainingService): ChainingPatientServiceUpdateDto {
        return {
            patient_service_id: service.id,
            is_active: service.isActive,
            quantity: service.quantity,
            status: service.status,
            provider_id: service.providerId
        } as ChainingPatientServiceUpdateDto
    }

    /**
     * Private method used to format a Chaining type into a ChainingPatientServiceCreateDto type
     * used to send data to clevehr
     * @param service
     * @private
     */
    private convertChainingToPatientServiceCreate(service: ChainingService): ChainingPatientServiceCreateDto {
        return {
            associated_patient_service_id: service.associatedPatientServiceId,
            patient_service_id: null,
            is_active: service.isActive,
            quantity: service.quantity,
            prestation_caisse_id: service.prestationCaisseId,
            status: ChainingPatientServiceStatus.pending,
            provider_id: service.providerId ?? this.chainingProviderId
            /*date_administrated: null,
            status_user_id: null*/
        } as ChainingPatientServiceCreateDto
    }


    /** ASSOCIATED PATIENT SERVICE PART */

    /**
     * This method should be only used by the root component to store the data
     * in a hidden clevehr input field
     * @param dataToSend
     * @protected
     */
    protected updateClevehrSharedChainingField(dataToSend: ChainingAssociatedPatientServiceCreateDto[]): void {
        this.$updateHiddenField(dataToSend)
    }

    /**
     *
     * @param chainingToAdd
     * @protected
     */
    protected addChainingAssociatedPatientService(chainingToAdd: Chaining) {
        this.$store.dispatch('addChainingAssociatedPatientService', {
            storeKey: this.storeKey,
            parameters: this.convertChainingToAssociatedPatientServiceCreates(chainingToAdd)
        } as ChainingStoreAddAssociatedPatientService ).then( (savedChaining: Chaining[]) => {
            if (savedChaining.length === 1 && this.chainingLastAssociatedPatientServiceId !== ChainingDefaultConstants.CHAINING_NO_INSERTED_ID) {
                chainingToAdd.associatedPatientServiceId = this.chainingLastAssociatedPatientServiceId
                chainingToAdd.services = _.cloneDeep(savedChaining[0].services)
                this.addInternalChaining(chainingToAdd)
            } else {
                // todo throw an error
                console.error('[addChainingAssociatedPatientService] - no returned id provided')
            }
        })
    }

    /**
     * Method used to update a chaining within an api call
     * @param chainingToUpdate
     * @protected
     */
    protected updateChainingAssociatedPatientService(chainingToUpdate: Chaining): Promise<any> {
        return this.$store.dispatch('updateChainingAssociatedPatientService', this.convertChainingToAssociatedPatientServiceUpdate(chainingToUpdate, chainingToUpdate.services.length > 0))
    }

    /**
     *
     * @param chainingToDelete
     * @protected
     */
    protected deleteChainingAssociatedPatientService(chainingToDelete: Chaining): Promise<any> {
        return this.$store.dispatch('deleteChainingAssociatedPatientService', this.convertChainingToAssociatedPatientServiceUpdate(chainingToDelete, chainingToDelete.services.length > 0))
    }

    /**
     * Use this method to ask root component to update the hidden clevehr input field
     * @protected
     */
    protected askClevehrUpdate(): void {
        this.$emit(this.ASK_CLEVEHR_UPDATE_EVENT, this.convertChainingToAssociatedPatientServiceCreates(this.internalChainings))
    }

    /**
     * Private method used to format a Chaining type into a ChainingPatientServiceUpdateDto type
     * used to send data to clevehr
     * @param chaining
     * @param parseServices
     * @private
     */
    private convertChainingToAssociatedPatientServiceUpdate(chaining: Chaining, parseServices: boolean = false): ChainingAssociatedPatientServiceUpdateDto {
        return {
            associated_patient_service_id: chaining.associatedPatientServiceId,
            is_active: chaining.isActive,
            services: parseServices ? this.convertChainingServiceAsChainingServiceSave(chaining.services) : null
        } as ChainingAssociatedPatientServiceUpdateDto
    }

    private convertChainingServiceAsChainingServiceSave(services: ChainingService[]): ChainingAssociatedPatientServiceService[] {
        return services.map((service) => {
            return {
                prestation_caisse_id: service.prestationCaisseId,
                quantity: service.quantity,
                provider_id: this.chainingProviderId
            } as ChainingAssociatedPatientServiceService
        })
    }

    /**
     * Private method used to format a Chaining type into a ChainingPatientServiceCreateDto type
     * used to send data to clevehr
     * @param chainings
     * @private
     */
    private convertChainingToAssociatedPatientServiceCreates(chainings: OneOrMore<Chaining>): OneOrMore<ChainingAssociatedPatientServiceCreateDto> {
        if (Array.isArray(chainings)) {
            return chainings.map((chaining) => this.convertChainingToAssociatedPatientServiceCreate(chaining, chaining.services.length > 0))
        } else {
            return this.convertChainingToAssociatedPatientServiceCreate(chainings, chainings.services.length > 0)
        }
    }

    private convertChainingToAssociatedPatientServiceCreate(chaining: Chaining, parseServices: boolean = false): ChainingAssociatedPatientServiceCreateDto {
        return {
            object_class: chaining.objectClass ? chaining.objectClass : this.objectClass,
            object_id: chaining.objectId ? chaining.objectId : this.objectId,
            specialty_id: chaining.specialtyId === ChainingDefaultConstants.CHAINING_EMPTY_SPECIALTY_ID ? null : chaining.specialtyId,
            associated_patient_service_id: null,
            chaining_id: chaining.id === ChainingDefaultConstants.CHAINING_VIRTUAL_ID ? null : chaining.id,
            is_active: chaining.isActive,
            services: parseServices ? this.convertChainingServiceAsChainingServiceSave(chaining.services) : null,
        } as ChainingAssociatedPatientServiceCreateDto
    }

    protected getAdministratedProductIconByType(type: ChainingCatalogContextEnum): string {
        return ChainingHelper.getContextIconByType(type)
    }
}
