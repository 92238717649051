import { KhButton, KhComponent, KhList, KhModal } from '@internal-libraries/kheops-ui-lib'
import { Component, Prop, VModel, Watch } from 'vue-property-decorator'
import { ChainingPanelList } from '../../types/ChainingKhList'
import ChainingService from '../../classes/ChainingService'
import { Nullable } from '@internal-libraries/kheops-ui-lib/dist/types/common/types/Helpers'

/**
 * ChainingServiceFoundSelector
 */
@Component({
    components: {
        KhModal,
        KhList,
        KhButton
    }
})
export default class ChainingServiceFoundSelector extends KhComponent {
    @VModel() dialog
    @Prop({default: () => []}) services!: ChainingService[]
    @Prop() code?: string
    @Watch('services')
    onServiceChange(value){
        this.setServiceListItems(value)
    }

    public selectedService: Nullable<ChainingService> = null

    get listServices(){
        this.setServiceListItems(this.services)
        return this.services
    }

    //========== DATA
    private key = 0
    private foundServiceListConfig: ChainingPanelList = {
        type: 'KhList',
        data: {
            selectedIndex: [],
            items: [],
            nonSelectableItems: [],
        },
        slots: {
            content: 'chainingService'
        }
    }

    //========== FUNCTIONALITY
    public setServiceListItems(services: ChainingService[]){
        this.foundServiceListConfig.data.items = services;
    }

    private updateSelectedServices(newSelection: ChainingService[]) {
            this.selectedService = newSelection[0] || null
    }

    private emitSelection(){
        this.$emit('onServiceSelected', this.selectedService)
        this.reset()
    }

    get chainingDuplicateServiceConfirmTrad(){
        return this.$t('chaining:chaining-service_code_duplicate', {
            code: '<strong>' + this.code + '</strong>',
            interpolation: {
                escapeValue: false,
            }
        })
    }

    private reset() {
        this.selectedService = null
        this.foundServiceListConfig.data.selectedIndex = []
        this.foundServiceListConfig.data.items = []
        this.key += 1
    }
}
