import { get } from 'lodash'
import { ApiDataAdapter } from '@internal-libraries/kheops-ui-lib/src/services/RestClient/Interfaces/ApiDataAdapter'
import { ChainingTypeDto } from '../types/ChainingTypeDto'
import ChainingType from '../classes/ChainingType'

export class ChainingTypeApiAdapter implements ApiDataAdapter {
    createClass(apiData: ChainingTypeDto): ChainingType {
        return new ChainingType(
            get(apiData, 'id',  -1),
            get(apiData, 'name', ''),
            get(apiData, 'entity_type', ''),
            get(apiData, 'is_active', true),
        )
    }
}
