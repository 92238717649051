import _ from 'lodash'
import { KhFormSchema, KhFormSchemaChild, KhFormSchemaRoot } from '@internal-libraries/kheops-ui-lib'
import { Undefinable, Nullable } from '@internal-libraries/kheops-ui-lib/dist/types/common/types/Helpers'
import { Component, Prop, Watch } from 'vue-property-decorator'
import ChainingComponentStore from '../ChainingComponentStore'
import { DrugDescriptionLanguage, DrugSelected } from '../../types/DrugSelected'
import {
    ChainingManagementContextData, ChainingManagementContextError,
    ChainingManagementContextFormData
} from '../../types/ChainingManagementContextFormData'
import { ChainingCatalogContextEnum } from '../../types/ChainingCatalogContext'
import ChainingType from '../../classes/ChainingType'

/**
 * ChainingManagementContext
 */

@Component({})
export default class ChainingManagementContext extends ChainingComponentStore {
    @Prop() value!: ChainingManagementContextFormData
    private formName: string = _.uniqueId('formChainingContext_');
    private validation?: ChainingManagementContextError

    private formData: ChainingManagementContextData = {}
    private contextsLoading = false

    public created() {
        this.loadContexts()
    }

    //@TODO move this into ui-lib, should be manage inside Form service
    @Watch('value', {immediate: true})
    onValueChange(value, oldValue) {
        this.formData = value.data

        if (!value) {
            this.resetContext()
        }
        if (!_.isEqual(value, oldValue) && value?.data) {
            if (value.data.context_select) {
                this.updateContext(value.data.context_select)
            }
        }
    }

    private loadContexts() {
        this.contextsLoading = true
        this.$store.dispatch('loadChainingTypes').finally(() => {
            this.contextsLoading = false

            this.form?.setElementProp('context_select', 'items', this.contexts)
        })
    }

    private onValidation(hasError: boolean) {
        this.validation = {
            formName: this.formName,
            hasError: hasError,
        } as ChainingManagementContextError

        this.$emit('input', {
            data: this.formData,
            validation: this.validation
        } as ChainingManagementContextFormData)
    }

    private context_name = ''

    private resultsAutocomplete = []
    private form?: KhFormSchema = new KhFormSchema()

    private drugContexts: any = [
        {id: ChainingCatalogContextEnum.CONTEXT_BASE, name: this.$t('chaining:chaining-compendium')},
        {id: ChainingCatalogContextEnum.CONTEXT_WMA, name: this.$t('chaining:chaining-wma')},
    ]

    private schemaFormChainingContext: KhFormSchemaRoot =
        {
            children: [
                {
                    component: 'row',
                    children: [
                        {
                            component: 'col',
                            props: {
                                class: 'col-md-3 col-3',
                            },
                            children: [
                                {
                                    type: 'KhAutocomplete',
                                    id: 'context_select',
                                    isVisible: true,
                                    props: {
                                        label: this.$t('chaining:chaining-search-context'),
                                        items: [],
                                        loading: this.contextsLoading,
                                        itemText: 'name',
                                        itemValue: 'id',
                                    },
                                    listeners: {
                                        '@change': this.contextChange,
                                    },
                                }
                            ]
                        },
                        {
                            component: 'col',
                            props: {
                                class: 'col-md-3 col-3',
                            },
                            id: 'context_catalog_drug_col',
                            isVisible: false,
                            children: [
                                {
                                    type: 'KhAutocomplete',
                                    id: 'context_catalog_drug',
                                    validation: 'required',
                                    props: {
                                        label: this.$t('chaining:chaining-catalog'),
                                        items: this.drugContexts,
                                        itemText: 'name',
                                        itemValue: 'id',
                                    },
                                    listeners: {
                                        '@change': this.onCatalogDrugChange,
                                    },
                                }
                            ]
                        },
                        {
                            component: 'col',
                            id: 'context_catalog_care_col',
                            isVisible: false,
                            props: {
                                class: 'col-md-3 col-3',
                            },
                            children: [
                                {
                                    type: 'KhAutocomplete',
                                    id: 'context_catalog_care',
                                    validation: 'required',
                                    props: {
                                        label: this.$t('chaining:chaining-catalog'),
                                        items: this.careCatalogs,
                                        itemText: 'name',
                                        itemValue: 'id',
                                    },
                                    listeners: {
                                        '@change': this.changeEndpointCare,
                                    },
                                }
                            ]
                        },
                        {
                            component: 'col',
                            props: {
                                class: 'col-md-4 col-4',
                            },
                            children: [
                                {
                                    type: 'KhAutocomplete',
                                    id: 'context_list',
                                    validation: 'required',
                                    validationMessages: {
                                        required: this.$t('clevehr:common-required_message')
                                    },
                                    isVisible: false,
                                    props: {
                                        label: this.$t('chaining:chaining-drug'),
                                        items: this.resultsAutocomplete,
                                        itemText: 'libelle',
                                        itemValue: 'id',
                                        endpoint: '',
                                        returnObject: true,
                                    },
                                    listeners: {
                                        '@change': this.fillChainingContextName,
                                    },
                                }
                            ]
                        },
                        {
                            component: 'col',
                            props: {
                                class: 'col-md-2 col-2',
                            },
                            id: 'context_therapeutic_book_col',
                            isVisible: false,
                            children: [
                                {
                                    type: 'KhCheckbox',
                                    id: 'context_therapeutic_book',
                                    props: {
                                        label: this.$t('chaining:chaining-booklet'),
                                    },
                                    listeners: {
                                        '@change': this.changeEndpointDrugBooklet,
                                    }
                                }
                            ]
                        }
                    ]
                }
            ]
        }

    public contextChange(selectedContext: number) {
        this.formData.context_list = undefined
    }

    public resetContext() {
        const elementsToHide = ['context_list', 'context_catalog_drug_col', 'context_catalog_care_col', 'context_therapeutic_book_col']

        elementsToHide
            .forEach((id: string) => {
                let element = this.form?.getElement(id)
                element && (element.isVisible = false)
            })
    }

    public updateContext(selectedContext: number) {
        const context_list: KhFormSchemaChild | undefined = this.form?.getElement('context_list')
        const context_catalog_drug_col: KhFormSchemaChild | undefined = this.form?.getElement('context_catalog_drug_col')
        const context_catalog_care_col: KhFormSchemaChild | undefined = this.form?.getElement('context_catalog_care_col')
        const context_therapeutic_book_col: KhFormSchemaChild | undefined = this.form?.getElement('context_therapeutic_book_col')
        const context_catalog_drug: KhFormSchemaChild | undefined = this.form?.getElement('context_catalog_drug')
        const context_catalog_care: KhFormSchemaChild | undefined = this.form?.getElement('context_catalog_care')
        const context_therapeutic_book: KhFormSchemaChild | undefined = this.form?.getElement('context_therapeutic_book')

        if (context_list && context_catalog_drug_col && context_catalog_care_col &&
            context_therapeutic_book_col && context_catalog_drug && context_catalog_care && context_therapeutic_book) {

            context_list.isVisible = false
            context_catalog_drug_col.isVisible = false
            context_therapeutic_book_col.isVisible = false
            context_catalog_care_col.isVisible = false

            // Retrieve code from context id
            const selectedContextCode: Nullable<ChainingCatalogContextEnum> = ChainingType.getContextFromId(selectedContext, this.contexts)?.code || null

            if (selectedContextCode === ChainingCatalogContextEnum.CONTEXT_DRUG) {
                context_list.props.label = this.$t('chaining:chaining-drug')
                context_list.isVisible = true
                context_catalog_drug_col.isVisible = true
                context_therapeutic_book_col.isVisible = this.formData.context_catalog_drug !== ChainingCatalogContextEnum.CONTEXT_WMA

                if (!this.formData.context_catalog_drug) {
                    context_catalog_drug.props.value = "base"
                    context_therapeutic_book.props.value = true
                }

                const chapter = this.formData.context_catalog_drug === ChainingCatalogContextEnum.CONTEXT_WMA ?
                    'wma' :
                    this.formData.context_therapeutic_book ? 'booklet' : 'base'

                this.updateContextListEndpoint(selectedContextCode, chapter)
            } else if (selectedContextCode === ChainingCatalogContextEnum.CONTEXT_CARE) {
                context_list.props.label = this.$t('chaining:chaining-care')
                context_list.isVisible = true
                context_catalog_care_col.isVisible = true

                if (!context_catalog_care.props.value) {
                    context_catalog_care.props.value = this.formData.context_catalog_care
                }
                context_catalog_care.props.items = this.careCatalogs
                context_therapeutic_book.props.value = ""
                context_therapeutic_book.props.checked = false

                if (!this.formData.context_catalog_care) {
                    this.formData.context_catalog_care = 'soin'
                }
                this.updateContextListEndpoint(selectedContextCode, this.formData.context_catalog_care)

            } else if (selectedContextCode === ChainingCatalogContextEnum.CONTEXT_OPERATION) {
                context_list.props.label = this.$t('chaining:chaining-intervention')
                context_list.isVisible = true

                this.updateContextListEndpoint(selectedContextCode, ChainingCatalogContextEnum.CONTEXT_OPERATION)
            } else {
                context_list.isVisible = false
                context_catalog_drug_col.isVisible = false
                context_catalog_care_col.isVisible = false
                context_therapeutic_book_col.isVisible = false
            }
        }
    }

    public fillChainingContextName(selectedContextValue: DrugSelected) {
        const context_list: KhFormSchemaChild | undefined = this.form?.getElement('context_list')
        const itemText = context_list ? context_list.props.itemText : 'description_' + this.drugBaseLanguage

        this.context_name = selectedContextValue[(itemText) as keyof DrugDescriptionLanguage]
    }

    public changeEndpointDrugBooklet(selectedBookletValue: boolean) {
        const chapter = selectedBookletValue ? 'booklet' : 'base'
        this.updateContextListEndpoint(ChainingCatalogContextEnum.CONTEXT_DRUG, chapter)
    }

    public onCatalogDrugChange(selectedCatalogDrug: any) {
        const context_therapeutic_book: Undefinable<KhFormSchemaChild> = this.form?.getElement('context_therapeutic_book')
        const context_catalog_drug: KhFormSchemaChild | undefined = this.form?.getElement('context_catalog_drug')

        if (context_catalog_drug?.props?.value) context_catalog_drug.props.value = selectedCatalogDrug
        if (context_therapeutic_book) {
            switch (selectedCatalogDrug) {
                case 'wma':
                    context_therapeutic_book.isVisible = false
                    this.formData.context_therapeutic_book = false
                    break
                default:
                    context_therapeutic_book.isVisible = true
            }
        }

        const context_list: KhFormSchemaChild | undefined = this.form?.getElement('context_list')
        if (context_list) {
            this.formData.context_list = undefined
            this.updateContextListEndpoint(ChainingCatalogContextEnum.CONTEXT_DRUG, selectedCatalogDrug)
        }
    }

    public changeEndpointCare(selectedCatalogcare: any) {
        this.updateContextListEndpoint(ChainingCatalogContextEnum.CONTEXT_CARE, selectedCatalogcare)
    }

    private updateContextListEndpoint(type: ChainingCatalogContextEnum, chapter: string) {
        const context_list: KhFormSchemaChild | undefined = this.form?.getElement('context_list')
        if (context_list) {
            context_list.props.items = []
            if (type === ChainingCatalogContextEnum.CONTEXT_OPERATION) {
                context_list.props.endpoint = 'api/v1/planning/protocoles/intervention?group_id=' + this.groupId
            } else if (type === ChainingCatalogContextEnum.CONTEXT_CARE) {
                context_list.props.endpoint = 'api/v1/prescription/elementPrescription/category?chapter=' + chapter
            } else {
                context_list.props.endpoint = 'api/v1/drugs/perType?type=' + chapter
            }
            context_list.props.itemText = (['base', 'booklet'].indexOf(chapter) !== -1) ? 'description_' + this.drugBaseLanguage : 'libelle'
        }
    }
}
