var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "chaining_management_service_list" },
    [
      _c(
        "div",
        { staticClass: "chaining_management_service_list_title" },
        [
          _c(
            "v-col",
            { staticClass: "chaining_management_service_list_title" },
            [
              _c("h1", [
                _vm._v(_vm._s(_vm.$t("chaining:chaining-linked-chaining"))),
              ]),
              _vm._v(" "),
              _c("Kh-chip", [
                _vm._v(_vm._s(_vm.serviceList.getServices().length)),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "text-right" },
            [
              _c(
                "kh-button",
                {
                  attrs: { color: "primary", appendIcon: "prestationAdd" },
                  on: {
                    click: function ($event) {
                      _vm.dialogAddService = true
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$t("chaining:chaining-service")) +
                      "\n            "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("kh-list", {
        key: _vm.key,
        attrs: {
          config: _vm.serviceList.getConfiguration(),
          "show-select": false,
        },
        scopedSlots: _vm._u([
          {
            key: "chainingService",
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  "v-row",
                  { staticClass: "align-center", attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "mr-5",
                        attrs: { cols: "auto", "align-self": "center" },
                      },
                      [
                        _c("kh-icon", {
                          staticClass: "selectedService",
                          attrs: { icon: "prestationAdd" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      [
                        _c(
                          "v-list-item-title",
                          { staticClass: "mx-0 selectedService" },
                          [_vm._v(_vm._s(item.name))]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-list-item-subtitle",
                          { staticClass: "mx-0 selectedService" },
                          [_vm._v(_vm._s(item.code))]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { attrs: { cols: "auto mr-5", "align-self": "center" } },
                      [
                        _c("QuantityPicker", {
                          key: item.id,
                          attrs: {
                            quantity: item.quantity,
                            maxValue: _vm.maxChainingServiceNumber,
                          },
                          on: {
                            "update-quantity": function ($event) {
                              return _vm.updateItemQuantity($event, item)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { attrs: { cols: "auto", "align-self": "center" } },
                      [
                        _c(
                          "kh-button",
                          {
                            staticClass: "selectedService",
                            attrs: { icon: "" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.deleteService(item)
                              },
                            },
                          },
                          [_c("kh-icon", { attrs: { icon: "trashOutline" } })],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "kh-modal",
        {
          attrs: {
            "max-width": "1000px",
            scrollable: "",
            transition: "dialog-bottom-transition",
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("kh-icon", { attrs: { icon: "link" } }),
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("chaining:chaining-add-service")) +
                      "\n        "
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.dialogAddService,
            callback: function ($$v) {
              _vm.dialogAddService = $$v
            },
            expression: "dialogAddService",
          },
        },
        [
          _vm._v(" "),
          _c("chaining-add-services", {
            attrs: { scan: _vm.scanMode },
            on: {
              "update:scan": function ($event) {
                _vm.scanMode = $event
              },
              onServicesSelected: _vm.onServicesSelected,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("kh-input-message", {
        model: {
          value: _vm.errorMessages,
          callback: function ($$v) {
            _vm.errorMessages = $$v
          },
          expression: "errorMessages",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }