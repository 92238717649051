import { ApiDataAdapter } from '@internal-libraries/kheops-ui-lib/src/services/RestClient/Interfaces/ApiDataAdapter'
import { get } from 'lodash'
import ChainingProvider  from '../classes/ChainingProvider'
import {ChainingProviderDto} from '../types/ChainingProvider/ChainingProviderDto'

export class ChainingProviderApiAdapter implements ApiDataAdapter {
    public createClass(apiData: ChainingProviderDto): ChainingProvider {
        return new ChainingProvider(
            Number(get(apiData, 'id')), //@TODO API returns number instead of string
            get(apiData, 'code'),
            get(apiData, 'label')
        )
    }
}
