var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { attrs: { elevation: "0", light: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c("kh-barcode", {
                attrs: {
                  "hide-details": "",
                  label: _vm.$t("chaining:chaining-search-service"),
                  "scan-label": _vm.$t("chaining:chaining-scan"),
                  "scan-mode": _vm.syncScanMode,
                },
                on: {
                  input: _vm.onInputDialogChange,
                  "update:scanMode": function ($event) {
                    _vm.syncScanMode = $event
                  },
                  "update:scan-mode": function ($event) {
                    _vm.syncScanMode = $event
                  },
                  barcodeDetected: _vm.searchBarcodes,
                },
                model: {
                  value: _vm.searchDialogList,
                  callback: function ($$v) {
                    _vm.searchDialogList = $$v
                  },
                  expression: "searchDialogList",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.syncScanMode && !_vm.serviceFoundModal && !_vm.serviceNotFoundModal
        ? _c(
            "v-row",
            { staticClass: "d-flex flex-column" },
            [
              _c("div", { staticClass: "kh-list-header" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("chaining:chaining-service-search-result")) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c(
                "v-col",
                [
                  _vm.loadingServices
                    ? _c("v-progress-circular", {
                        attrs: {
                          size: 50,
                          color: "primary",
                          indeterminate: "",
                        },
                      })
                    : _vm.serviceList.getServices().length === 0
                    ? _c("span", { staticClass: "subtitle" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("chaining:chaining-import-service-seek")
                          )
                        ),
                      ])
                    : _c("kh-list", {
                        key: _vm.key,
                        attrs: {
                          config: _vm.serviceListConfiguration,
                          "show-select": "",
                          multiple: "",
                          returnFlat: "",
                          selectable: "",
                          displayCheckboxes: "",
                        },
                        on: { onSelectionChange: _vm.updateSelectedServices },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "chainingService",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "align-center",
                                      attrs: { "no-gutters": "" },
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mr-5",
                                          attrs: {
                                            cols: "auto",
                                            "align-self": "center",
                                          },
                                        },
                                        [
                                          _c("kh-icon", {
                                            attrs: { icon: "prestation" },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "service-search-ellipsis",
                                        },
                                        [
                                          _c(
                                            "v-list-item-title",
                                            { staticClass: "mx-0" },
                                            [_vm._v(_vm._s(item.name))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-list-item-subtitle",
                                            { staticClass: "mx-0" },
                                            [_vm._v(_vm._s(item.code))]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1105071910
                        ),
                      }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "d-flex flex-column" },
        [
          _c(
            "div",
            { staticClass: "kh-list-header d-flex align-center" },
            [
              _c("span", [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("chaining:chaining-service-search-result")) +
                    "\n            "
                ),
              ]),
              _vm._v(" "),
              _c("v-badge", {
                attrs: {
                  inline: "",
                  value: true,
                  content: _vm.selectedServiceListLength,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            [
              _vm.selectedServiceList.getServices().length === 0
                ? _c("span", { staticClass: "subtitle" }, [
                    _vm._v(
                      _vm._s(_vm.$t("chaining:chaining-service-cart-empty"))
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("kh-list", {
                key: _vm.key,
                staticClass: "kh-list-cart",
                attrs: {
                  config: _vm.selectedServiceListConfiguration,
                  "show-select": "",
                  multiple: "",
                  returnFlat: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "chainingService",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c(
                          "v-row",
                          {
                            staticClass: "align-center",
                            attrs: { "no-gutters": "" },
                          },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "mr-5",
                                attrs: { cols: "auto", "align-self": "center" },
                              },
                              [
                                _c("kh-icon", {
                                  staticClass: "selectedService",
                                  attrs: { icon: "prestationAdd" },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              { staticClass: "service-cart-ellipsis" },
                              [
                                _c(
                                  "v-list-item-title",
                                  { staticClass: "mx-0 selectedService" },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(item.name) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-list-item-subtitle",
                                  { staticClass: "mx-0 selectedService" },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(item.code) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                attrs: {
                                  cols: "auto mr-5",
                                  "align-self": "center",
                                },
                              },
                              [
                                _c("QuantityPicker", {
                                  key: item.id,
                                  attrs: {
                                    quantity: item.quantity,
                                    maxValue: _vm.maxChainingServiceNumber,
                                  },
                                  on: {
                                    "update-quantity": function ($event) {
                                      return _vm.updateItemQuantity(
                                        $event,
                                        item
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                attrs: { cols: "auto", "align-self": "center" },
                              },
                              [
                                _c(
                                  "kh-button",
                                  {
                                    staticClass: "selectedService",
                                    attrs: { icon: "" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.unselectServiceFromSelectedList(
                                          item
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("kh-icon", {
                                      attrs: { icon: "trashOutline" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "d-flex justify-end" },
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "kh-button",
                {
                  attrs: {
                    disabled: _vm.selectedServices.length === 0,
                    block: "",
                    color: "primary",
                    depressed: "",
                  },
                  on: { click: _vm.save },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$t("chaining:chaining-add-selection")) +
                      "\n            "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("chaining-service-found-selector", {
        attrs: {
          code: _vm.barcodeScanned,
          services: _vm.serviceFoundDuplicate,
        },
        on: { onServiceSelected: _vm.onFoundSelectorSelection },
        model: {
          value: _vm.serviceFoundModal,
          callback: function ($$v) {
            _vm.serviceFoundModal = $$v
          },
          expression: "serviceFoundModal",
        },
      }),
      _vm._v(" "),
      _c(
        "kh-confirm-modal",
        {
          attrs: { "max-width": "500", persistent: false },
          on: {
            cancel: _vm.onServiceNotFoundClose,
            ok: _vm.onServiceNotFoundClose,
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("chaining:chaining-service_code_scan")) +
                      "\n        "
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.serviceNotFoundModal,
            callback: function ($$v) {
              _vm.serviceNotFoundModal = $$v
            },
            expression: "serviceNotFoundModal",
          },
        },
        [
          _vm._v(" "),
          _c(
            "div",
            [
              _c("v-container", [
                _c("p", {
                  domProps: {
                    innerHTML: _vm._s(_vm.chainingServiceCodeNotFoundTrad),
                  },
                }),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("chaining:chaining-contact_admin_for_param"))
                  ),
                ]),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }