import {ApiDataAdapter} from "@internal-libraries/kheops-ui-lib/src/services/RestClient/Interfaces/ApiDataAdapter"
import {get} from "lodash"
import { ChainingCareCatalogTypeRef } from "./ChainingCareCatalogTypeRef"
import { ChainingCareCatalogTypeDto } from "../types/ChainingCareCatalogTypeDto"

export class ChainingCareCatalogTypeApiAdapter implements ApiDataAdapter {
    createClass(apiData: ChainingCareCatalogTypeDto): ChainingCareCatalogTypeRef {
        return new ChainingCareCatalogTypeRef(
            get(apiData, 'id'),
            get(apiData, 'name')
        )
    }
}
