import { ApiDataAdapter } from '@internal-libraries/kheops-ui-lib/src/services/RestClient/Interfaces/ApiDataAdapter'
import Chaining from '../classes/Chaining'
import { ChainingElementDto } from '../types/ChainingElement/ChainingElementDto'
import {ChainingApiAdapter} from './ChainingApiAdapter'

export class ChainingFromServiceApiAdapter implements ApiDataAdapter {
    public createClass(apiData: ChainingElementDto): Chaining {
        let returnedChaining: Chaining = new ChainingApiAdapter().createClass(apiData)

        returnedChaining.services.forEach( service => {
            service.chainingId = returnedChaining.id
        })

        return returnedChaining
    }
}
