import {Nullable} from '@internal-libraries/kheops-ui-lib/dist/types/common/types'
import ChainingService from './ChainingService'
import ChainingCatalog from './ChainingCatalog'
import * as ChainingDefaultConstants from '../types/ChainingDefault/ChainingDefaultConstants'
import {ChainingIconAndName} from '../types/ChainingIconAndName'
import AdministratedProduct from '../classes/AdministratedProduct'
import _ from 'lodash'

export default class Chaining {
    public selected = false
    public selectedState = 1
    public selectedServices: number[] = []
    public nonSelectableItems: number[] = []
    public specialtyName = ''
    public categoryName = ''
    public associatedPatientServiceId: Nullable<number> = null
    public contextsNames: ChainingIconAndName[] = []
    // objectClass and objectId refers to which object this chaining is attached for
    // useful in health care followup
    public objectClass: Nullable<string> = null
    public objectId: Nullable<number> = null
    public administratedProduct: Nullable<AdministratedProduct> = null

    constructor(
        public id: Nullable<number>,
        public name: string,
        public specialtyId: number = ChainingDefaultConstants.CHAINING_EMPTY_SPECIALTY_ID,
        public categoryId: number = ChainingDefaultConstants.CHAINING_NO_INSERTED_ID,
        public isActive: boolean = true,
        public services: ChainingService[] = [],
        public catalogs: ChainingCatalog[] = []
    ){}

    /**
     * Filter by name
     * Case-insensitive and ignore accents
     */
    public static filterByName(list: Chaining[], search: string): Chaining[] {
        const keywords = search.replace(/\s+/g, ' ').split(' ')
        // filtering chaining
        return list.filter((chaining: Chaining) => {
            const name = _.camelCase(chaining.name)
            for (let keyword of keywords) {
                if (name.search(new RegExp(_.escapeRegExp(_.camelCase(keyword)), 'i')) === -1) {
                    return false
                }
            }
            return true
        })
    }
}
