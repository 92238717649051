import Chaining from '../classes/Chaining'

export default class AdministratedProduct {
    public creationDate: Date
    public administratedDate: Date

    public selected = false
    public selectedState = 1
    public selectedChainings: number[] = []
    public nonSelectableItems: number[] = []

    constructor(
        public id: number,
        public objectClass: string,
        public administratorId: number,
        public quantity: number,
        public unit: string,
        public label: string,
        public type: string,
        public chainings: Chaining[],
        creationDate: string,
        administratedDate: string
    ){
        this.creationDate = new Date(creationDate)
        this.administratedDate = new Date(administratedDate)
    }
}
