import {ApiDataAdapter} from "@internal-libraries/kheops-ui-lib/src/services/RestClient/Interfaces/ApiDataAdapter"
import { ChainingCareCatalogDto } from '../types/ChainingCareCatalogDto'
import {get} from "lodash"
import {ChainingCareCatalog} from '../classes/ChainingCareCatalog'
import { CategoryPrescriptionAdapter } from './CategoryPrescriptionAdapter'
import { ChainingDrugCatalog } from "../classes/ChainingDrugCatalog"
import { ChainingDrugCatalogDto } from "../types/ChainingDrugCatalogDto"

export class ChainingDrugCatalogApiAdapter implements ApiDataAdapter {
    createClass(apiData: ChainingDrugCatalogDto): ChainingDrugCatalog {
        return new ChainingDrugCatalog(
            get(apiData, 'id', -1),
            get(apiData, 'description_french', ''),
            get(apiData, 'description_german', ''),
        )
    }
}
