import { get } from 'lodash'
import { ApiDataAdapter } from '@internal-libraries/kheops-ui-lib/src/services/RestClient/Interfaces/ApiDataAdapter'
import { OneOrMore, Undefinable } from '@internal-libraries/kheops-ui-lib/dist/types/common/types/Helpers'
import Chaining from '../classes/Chaining'
import ChainingService  from '../classes/ChainingService'
import ChainingCatalog from '../classes/ChainingCatalog'
import { ChainingServiceApiAdapter } from './ChainingServiceApiAdapter'
import { ChainingCatalogApiAdapter } from './ChainingCatalogApiAdapter'
import { ChainingElementDto } from '../types/ChainingElement/ChainingElementDto'
import { ChainingCatalogDto } from '../types/ChainingCatalogDto'
import { ChainingServiceDto } from '../types/ChainingServiceDto'

export class ChainingApiAdapter implements ApiDataAdapter {
    public createClass(apiData: ChainingElementDto): Chaining {
        let catalogsDto: Undefinable<OneOrMore<ChainingCatalogDto>> = get(apiData, 'catalogs')
        let catalogs: ChainingCatalog[] = []
        let servicesDto: Undefinable<OneOrMore<ChainingServiceDto>> = get(apiData, 'services')
        let services: ChainingService[] = []

        if(catalogsDto){
            if(Array.isArray(catalogsDto)){
                catalogs = catalogsDto.map(catalog => new ChainingCatalogApiAdapter().createClass(catalog))
            } else {
                catalogs = [new ChainingCatalogApiAdapter().createClass(catalogsDto)]
            }
        }

        if(servicesDto){
            if(Array.isArray(servicesDto)){
                services = servicesDto.map(service => {
                    return new ChainingServiceApiAdapter().createClass(service)
                })
            } else {
                services = [new ChainingServiceApiAdapter().createClass(servicesDto)]
            }
        }

        return new Chaining(
            get(apiData, 'id', -1),
            get(apiData, 'name', ''),
            get(apiData, 'specialty_id', -1),
            get(apiData, 'category_id', -1),
            get(apiData, 'is_active', true),
            services,
            catalogs
        )
    }
}
