import { ApiDataAdapter } from '@internal-libraries/kheops-ui-lib/src/services/RestClient/Interfaces/ApiDataAdapter'
import { get } from 'lodash'
import ChainingService from '../classes/ChainingService'
import { ChainingServiceDto } from '../types/ChainingServiceDto'
import {ChainingUserApiAdapter} from './ChainingUserApiAdapter'

export class ChainingServiceApiAdapter implements ApiDataAdapter {
    createClass(service: ChainingServiceDto): ChainingService {
        const chainingUserAdapter = new ChainingUserApiAdapter()
        return new ChainingService(
            get(service, 'id'),
            get(service, 'chaining_id'),
            get(service, 'prestationCaisse').id,
            get(service, 'prestationCaisse').libelle,
            get(service, 'prestationCaisse').code,
            get(service, 'provider_id'),
            get(service, 'quantity'),
            get(service, 'status'),
            get(service, 'creation_date'),
            chainingUserAdapter.createClass(get(service, 'user')),
            get(service, 'update_date'),
            chainingUserAdapter.createClass(get(service, 'statusUser'))
        )
    }
}
