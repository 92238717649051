import { ApiDataAdapter } from '@internal-libraries/kheops-ui-lib/src/services/RestClient/Interfaces/ApiDataAdapter'
import { get } from 'lodash'
import ChainingService from '../classes/ChainingService'
import { ChainingPrestationCaisseDto } from '../types/ChainingPrestationCaisse/ChainingPrestationCaisseDto'
import {ChainingServiceApiAdapter} from './ChainingServiceApiAdapter'
import {ChainingServiceDto} from '../types/ChainingServiceDto'

export class ChainingCashBenefitToServiceApiAdapter implements ApiDataAdapter {
    public createClass(cashBenefit: ChainingPrestationCaisseDto): ChainingService {
        return new ChainingServiceApiAdapter().createClass({
            id: null,
            prestationCaisse: cashBenefit,
        } as ChainingServiceDto)
    }
}
