import { OneOrMore } from '@internal-libraries/kheops-ui-lib/dist/types/common/types'
import Vue from 'vue'
import { PermissionsApiAdapter } from './PermissionsApiAdapter'
import PermissionsDto from './PermissionsDto'

export class PermissionsService {
    private permissionList: Record<string, boolean> = {}

    private getAcl(keys: OneOrMore<string>): boolean {
        if (Array.isArray(keys)) {
            for (const key of keys) {
                if (!this.permissionList[key]) return false
            }
            return true
        }
        return this.permissionList[keys] || false
    }

    public isAllowed(keys: OneOrMore<string>): boolean {
        return this.getAcl(keys)
    }

    public isDenied(keys: OneOrMore<string>): boolean {
        return !this.getAcl(keys)
    }

    public load(module: string): Promise<PermissionsDto> {
        const apiCall = Vue.prototype.$http.get('api/v1/admin/acl/' + module, new PermissionsApiAdapter())
        apiCall.subscribe(
                (data: PermissionsDto) => {
                    for (const [key, value] of Object.entries(data)) {
                        // we delete basic keys from api return
                        if (key === 'id' || key === '_type') continue
                        this.permissionList[key] = value
                    }
                }
            )
        return apiCall.toPromise()
    }

    public set(key: string, value: boolean): void {
        this.permissionList[key] = value
    }
}
