import {OneOrMore} from '@internal-libraries/kheops-ui-lib/dist/types/common/types'
import { ApiDataAdapter } from '@internal-libraries/kheops-ui-lib/src/services/RestClient/Interfaces/ApiDataAdapter'
import Vue from 'vue'
import { get } from 'lodash'
import Chaining from '../classes/Chaining'
import {ChainingDefaultDto} from '../types/ChainingDefault'
import * as ChainingDefaultConstants from '../types/ChainingDefault/ChainingDefaultConstants'

import { ChainingElementDto } from '../types/ChainingElement/ChainingElementDto'
import {ChainingServiceDto} from '../types/ChainingServiceDto'
import { ChainingApiAdapter } from './ChainingApiAdapter'
import { ChainingServiceApiAdapter } from './ChainingServiceApiAdapter'


export class ChainingDefaultApiAdapter implements ApiDataAdapter {
    public createClass(apiData: ChainingDefaultDto): Chaining {
        let chainingFromApi: ChainingElementDto = {...get(apiData, 'chaining')}
        if (chainingFromApi) {
            if (!chainingFromApi.id) {
                chainingFromApi.id = ChainingDefaultConstants.CHAINING_VIRTUAL_ID
                chainingFromApi.name = Vue.$i18n.translate('chaining:chaining-additional-services')
                chainingFromApi.category_id = ChainingDefaultConstants.CHAINING_NO_INSERTED_ID
                chainingFromApi.specialty_id = apiData.specialty_id || ChainingDefaultConstants.CHAINING_EMPTY_SPECIALTY_ID
            }
            // in fact the chaining is_active field corresponds to the api returned
            // i.e. apiData can be an AssociatedPatientService containing reference(s) to chainings from the catalog
            // then we need to re-sync the is_active field
            chainingFromApi.is_active = apiData.is_active

            let returnedChaining: Chaining = new ChainingApiAdapter().createClass(chainingFromApi)
            returnedChaining.associatedPatientServiceId = apiData.id

            let currentPatientService: OneOrMore<ChainingServiceDto> = get(apiData, 'chainingPatientService')

            if (Array.isArray(currentPatientService)) {
                returnedChaining.services = currentPatientService.map(patientService => {
                    return new ChainingServiceApiAdapter().createClass(patientService)
                })
            } else if (currentPatientService) {
                returnedChaining.services = [new ChainingServiceApiAdapter().createClass(currentPatientService)]
            } else {
                returnedChaining.services = []
            }

            returnedChaining.services.forEach(service => {
                service.chainingId = chainingFromApi.id
                service.associatedPatientServiceId = apiData.id
                service.chainingParent = returnedChaining
            })
            return returnedChaining
        } else {
            return new Chaining(ChainingDefaultConstants.CHAINING_NO_INSERTED_ID, '')
        }
    }
}
