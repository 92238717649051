import _ from 'lodash'
import {Component, PropSync} from 'vue-property-decorator'
import {
    KhIcon,
    KhButton,
    KhCheckbox,
    KhSelect,
    KhDatatable,
    KhList,
    KhSwitch,
    KhBarcode,
    KhConfirmModal,
    DetectedBarcode,
    Snack,
} from '@internal-libraries/kheops-ui-lib'
import {ClevehrDebounceTime} from '../../../../../core/vue/ClevehrDebounceTime'
import {ChainingStoreServices} from '../../types/ChainingStoreServices'
import ChainingComponentStore from '../ChainingComponentStore'
import ChainingService from '../../classes/ChainingService'
import {ServiceKhList} from '../../types/ServiceKhList'
import QuantityPicker from '@input/QuantityPicker'
import {ChainingCashBenefitToServiceApiAdapter} from '../../adapter/ChainingCashBenefitApiAdapter'
import ChainingServiceFoundSelector from '../ChainingServiceFoundSelector'
import {Undefinable} from '@internal-libraries/kheops-ui-lib/dist/types/common/types'

/**
 * ChainingSearchServices
 */
@Component({
    components: {
        KhIcon,
        KhButton,
        KhCheckbox,
        KhSelect,
        KhConfirmModal,
        KhDatatable,
        KhList,
        QuantityPicker,
        KhSwitch,
        KhBarcode,
        ChainingServiceFoundSelector
    }
})
export default class ChainingAddServices extends ChainingComponentStore {
    @PropSync('scan', {default: false, type: Boolean}) syncScanMode!: Boolean

    //========== CONST
    static readonly VIRTUAL_ITEM_NUMBER = 3
    static readonly VIRTUAL_ITEM_HEIGHT = 42

    //========== DATA
    private searchDialogList = ''
    private catalog = 0
    private loadingServices = false
    private onInputDialogChange: any
    private key = 0
    private selectedServiceList: ServiceKhList = new ServiceKhList([], true, 5, 44)
    private notDisplayedServices: ChainingService[] = []
    private serviceFoundModal = false
    private serviceNotFoundModal = false
    private serviceFoundDuplicate: ChainingService[] = []
    private barcodeScanned = ''
    //========== FUNCTIONALITY
    private get selectedServiceListConfiguration() {
        return this.selectedServiceList.getConfiguration()
    }

    private get selectedServiceListLength(): string {
        return this.selectedServiceList.getServices().length.toString()
    }

    private get chainingServiceCodeNotFoundTrad() {
        return this.$t('chaining:chaining-service_code_not_found', {
            code: '<strong>' + this.barcodeScanned + '</strong>',
            interpolation: {
                escapeValue: false,
            }
        })
    }

    private created() {
        this.onInputDialogChange = _.debounce(this.loadServices, ClevehrDebounceTime.short)
        this.serviceList.setVirtualOptions(ChainingAddServices.VIRTUAL_ITEM_NUMBER, ChainingAddServices.VIRTUAL_ITEM_HEIGHT)
    }

    /**
     * basic reset method to empty every variables
     * @private
     */
    private reset() {
        this.searchDialogList = ""
        this.notDisplayedServices = []
        this.setServiceList([])
        this.selectedServiceList.updateConfiguration([])
        this.selectedServices = []
    }

    /**
     * on service not found modal close
     */
    private onServiceNotFoundClose() {
        this.serviceNotFoundModal = false
        this.syncScanMode = true
    }

    /**
     * Save method, emit the selected services (cart)
     */
    private save() {
        this.$emit("onServicesSelected", this.selectedServices)
        this.reset()
    }

    /**
     * Load service list based on a search string
     */
    private loadServices(): void {
        // launch the search only if we have a search string
        if (this.searchDialogList) {
            this.loadingServices = true
            this.$store.dispatch('loadChainingServices', {
                searchDialogList: this.searchDialogList,
                objectId: this.catalog,
                storeKey: this.storeKey
            } as ChainingStoreServices)
                .then(() => {
                    this.setServiceList(this.chainingServices)
                    this.notDisplayedServices = this.serviceList.updateServiceWithSelectedServices(this.selectedServices)
                })
                .finally(() => {
                    this.loadingServices = false
                })
        }
    }

    /**
     * Load service list based on a search string
     */
    private loadServicesByCode(code: string): void {
        this.$http.get<ChainingService[]>('api/v1/tarmed/prestationCaisse/barcode/' + code, new ChainingCashBenefitToServiceApiAdapter())
            .subscribe((chainingServices) => {
                let services = chainingServices as ChainingService[]
                this.barcodeScanned = code

                services.forEach(service => {
                    service.quantity = 1
                })
                if (!services.length) {
                    this.serviceNotFoundModal = true
                    this.syncScanMode = false
                } else if (services.length > 1) {
                    this.serviceFoundModal = true
                    this.serviceFoundDuplicate = services

                } else {
                    this.addSelectedService(services[0])
                }
            })
    }

    /**
     * Whenever the user clicks on an item from the search list, this function is triggered
     * It updates the user's cart and set the item quantity to one by default
     * @param newSelection
     * @private
     */
    private updateSelectedServices(newSelection: ChainingService[]) {
        newSelection.forEach(service => {
            if (service.quantity < 1) service.quantity = 1
        })
        this.selectedServices = this.notDisplayedServices.concat(newSelection)
        this.selectedServiceList.updateConfiguration(this.selectedServices)
    }

    /**
     * Add services to the list of selected services except if already exist
     * It updates the user's cart and set the item quantity to one by default
     * @param newSelection
     * @private
     */
    private addSelectedService(selectedService: ChainingService) {
        selectedService.quantity = selectedService.quantity < 1 ? 1 : selectedService.quantity

        let serviceFound: Undefinable<ChainingService> = this.selectedServices.find(
            service => service.prestationCaisseId === selectedService.prestationCaisseId
        )

        //not found => add it
        if (!serviceFound) {
            this.selectedServices.push(selectedService)
            this.selectedServiceList.updateConfiguration(this.selectedServices)

            this.snackbarManager.addSnack(new Snack(
                this.$t('chaining:chaining-service_added', {count : 1}),
            ))
        } else { //not found => increment quantity
            serviceFound.quantity += 1
            this.key += 1

            this.snackbarManager.addSnack(new Snack(
                this.$t('chaining:chaining-service_added_quantity', {count : 1, name: serviceFound.name}),
            ))
        }
    }

    /**
     * Whenever the user clicks on the trash button to unselected an item from its cart
     * this function is triggered
     * @param serviceToRemove
     * @private
     */
    private unselectServiceFromSelectedList(serviceToRemove: ChainingService): void {
        this.selectedServices = this.selectedServiceList.delete(serviceToRemove)
        this.notDisplayedServices = this.serviceList.updateServiceWithSelectedServices(this.selectedServices)
    }

    /**
     * load service on barcode detection
     * @param barcode
     * @private
     */
    private searchBarcodes(barcode: DetectedBarcode) {
        this.loadServicesByCode(barcode.code)
    }


    /**
     * add service choosed by the user when on multi code detection modal
     * @param selectedService
     * @private
     */
    private onFoundSelectorSelection(selectedService: ChainingService) {
        this.addSelectedService(selectedService)
        this.serviceFoundModal = false
        this.barcodeScanned = ''
        this.syncScanMode = true
    }
}
