import { get } from 'lodash'
import { ApiDataAdapter } from '@internal-libraries/kheops-ui-lib/src/services/RestClient/Interfaces/ApiDataAdapter'
import { ChainingSpecialtyDto } from '../types/ChainingSpecialtyDto'
import ChainingSpecialty from '../classes/ChainingSpecialty'


export class ChainingSpecialtyApiAdapter implements ApiDataAdapter {
    createClass(apiData: ChainingSpecialtyDto): ChainingSpecialty {
        return new ChainingSpecialty(
            get(apiData, 'date_created', ''),
            get(apiData, 'date_modified', ''),
            get(apiData, 'id',  -1),
            get(apiData, 'name', ''),
        )
    }
}
