import { Component, Prop, VModel, Watch } from 'vue-property-decorator'
import { KhComponent } from '@internal-libraries/kheops-ui-lib'
import QuantityPicker from '@input/QuantityPicker'
import ChainingService from '../../classes/ChainingService'
import ChainingHelper from '../../classes/ChainingHelper'
import ChainingAddServices from '../ChainingAddServices'
import {ServiceKhList} from '../../types/ServiceKhList'

/**
 * ChainingManagementServiceList
 */

@Component({
    components: { QuantityPicker, ChainingAddServices },
})
export default class ChainingManagementServiceList extends KhComponent {
    @VModel({default : () => []}) services!: ChainingService[]
    @Prop({default: () => []}) errorMessages!:  string[]
    private ITEM_NUMBER = 5
    private ITEM_HEIGHT = 55
    private key = 1
    private dialogAddService = false
    private serviceList: ServiceKhList = new ServiceKhList([])
    protected selectedServices: ChainingService[] = []
    protected maxChainingServiceNumber = ChainingHelper.MAX_SERVICE_NUMBER
    private scanMode = false

    private created() {
        this.serviceList.setVirtualOptions(this.ITEM_NUMBER, this.ITEM_HEIGHT)
    }

    public updateItemQuantity(quantity, item): void {
        item.quantity = quantity
    }

    @Watch('dialogAddService')
    onScanModeServiceChange(newValue: boolean, oldValue: boolean){
        if(!newValue) {
            this.scanMode = false
        }
    }

    @Watch('value', {immediate: true})
    onServiceListChange(serviceList: ChainingService[]){
        this.serviceList = new ServiceKhList(serviceList)
    }

    private onServicesSelected(selectedServices: ChainingService[]) {
        const services = this.serviceList.getServices()
        this.selectedServices = services ? this.serviceList.getServices().concat(selectedServices) : selectedServices
        this.serviceList.updateConfiguration(this.selectedServices)
        this.dialogAddService = false
        this.emitInput(this.selectedServices)
    }

    /**
     * Whenever the user clicks on the trash button to delete an item
     * this function is triggered
     * @param serviceToRemove
     * @private
     */
    private deleteService(serviceToRemove: ChainingService): void {
        this.selectedServices = this.serviceList.delete(serviceToRemove)
        this.emitInput(this.selectedServices)
    }

    private emitInput(selectedServices: ChainingService[]){
        this.$emit('input', selectedServices.length ? selectedServices : "")
    }
}
